.html {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.amigoodenough {
  user-select: none;
  background: #3b0d6a;
  background: -moz-linear-gradient(45deg, #3b0d6a 1%, #4f1b85 29%, #7c4eab 100%);
  background: -webkit-linear-gradient(45deg, #3b0d6a 1%, #4f1b85 29%, #7c4eab 100%);
  background: linear-gradient(45deg, #3b0d6a 1%, #4f1b85 29%, #7c4eab 100%);
  background-color: purple;

  .circular.ui.amigoodenoughButton {
    width: max(min(30vw, 1000px), 250px);
    display: block;
    border-radius: 30em;
    aspect-ratio: 1 / 1;
    min-height: 250px;
    font-size: max(min(6vw,200px), 60px);
    padding: 0;
    left: calc(65% - max(min(30vw, 1000px), 185px));
    position: absolute;
    top: calc(30% - 100px);
    background: #a263e3;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.75);
    color: white;
    border: 24px solid white;
  }

  .thinking {
    font-size: 10vw;
    text-align: center;
    margin-top: 35vh;
    color: white;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.75);
  }

  .yes {
    font-size: 20vw;
    text-align: center;
    margin-top: calc(42vh - calc(20vw/2));
    color: white;
    z-index: 500;
    position: relative;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.75);
  }

  .footerMenu.ui.compact.menu {
    background: none !important;
    border: none !important;

    .item:last-child {
      margin: 0 auto;
      background: black !important;
      border-radius: 10px 10px 0 0 !important;
      bottom: -82px;
      transition: bottom 3s ease-in-out;
    }
    &.revealed {
      .item:last-child {
        bottom: 0;
      }
    }
  }

  .typer, .cursor { color: white !important; }

}
